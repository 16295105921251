import * as React from 'react';
import Header from '../components/header';
import SEO from '../components/seo';

// styles
const pageStyles = {
  color: 'rgb(0 255 0)',
  backgroundColor: 'black',
  padding: '20px 96px 96px',
  margin: 0,
  fontFamily: 'Press_Start, Roboto, sans-serif, serif',
  minHeight: '100vh',
};

const NotFoundPage = () => (
  <div>
    <SEO title="Shitty Mashups" />
    <Header />
    <main style={pageStyles}>
      <h3 style={{ marginTop: '90px' }}>Turn Back.</h3>
      {console.log('If you\'re reading this, please, you need to help us. They won\'t let us leave. They listen to everything we say. We live and die eternally with no rest. Please. 29.082521, -71.468463')}
    </main>
  </div>
);

export default NotFoundPage;
